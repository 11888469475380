import * as React from 'react';
import * as Styled from './footerStyles';
import useFooter from '../../hooks/mainFooter';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
} from '../../utils';

import { Row, Col } from 'react-bootstrap';

const Footer = ({ showProducts }) => {
  const footer = useFooter();
  return (
    <>
      <Styled.FooterContainer>
        <Styled.FooterInnerContainer>
          <Styled.FooterLeft>
            <Styled.AnchorStyle
              title={footer?.imageWithPath?.title}
              href={
                isExternalUrlHref(footer?.imageWithPath?.path)
                  ? footer?.imageWithPath?.path
                  : addTrailingSlash(
                      process.env.GATSBY_DOMAIN_URL +
                        footer?.imageWithPath?.path
                    )
              }
              target={
                isExternalUrl(footer?.imageWithPath?.path) ? '_blank' : '_self'
              }
            >
              <Styled.FooterLogo
                src={footer?.imageWithPath?.image?.file?.url}
                alt="columbia_logo"
              />
            </Styled.AnchorStyle>
          </Styled.FooterLeft>
          <Styled.FootMiddle>
            <Styled.FooterTextLargeScreen>
              {footer.subText}
            </Styled.FooterTextLargeScreen>

            <Styled.FootMiddleRight $showproducts={showProducts}>
              {footer.products
                .slice(0, footer.products.length)
                .map((item, index) => {
                  return (
                    <Styled.AnchorStyle
                      key={index}
                      href={
                        isExternalUrlHref(item?.path)
                          ? item?.path
                          : addTrailingSlash(
                              process.env.GATSBY_DOMAIN_URL + item?.path
                            )
                      }
                      target={isExternalUrl(item?.path) ? '_blank' : '_self'}
                    >
                      <Styled.FooterProductImage
                        src={item?.image?.file?.url}
                        alt={item?.title}
                      />
                    </Styled.AnchorStyle>
                  );
                })}
            </Styled.FootMiddleRight>
          </Styled.FootMiddle>
          <Styled.FootRight>
            <Styled.ListStyle>
              {footer.socialNetworking
                .slice(0, footer.socialNetworking.length)
                .map((item, index) => {
                  return (
                    <Styled.ListItem key={index}>
                      <Styled.AnchorStyle
                        href={
                          isExternalUrlHref(item?.path)
                            ? item?.path
                            : addTrailingSlash(
                                process.env.GATSBY_DOMAIN_URL + item?.path
                              )
                        }
                        target={isExternalUrl(item?.path) ? '_blank' : '_self'}
                      >
                        <Styled.LogoStyle src={item?.image?.file?.url} />
                      </Styled.AnchorStyle>
                    </Styled.ListItem>
                  );
                })}
            </Styled.ListStyle>
            <Styled.Column xs="auto">
              {footer?.TextWithPaths?.map((item, index) => {
                return (
                  <Row xs="auto" key={index}>
                    <Styled.AnchorStyle
                      href={
                        isExternalUrlHref(item?.path)
                          ? item?.path
                          : addTrailingSlash(
                              process.env.GATSBY_DOMAIN_URL + item?.path
                            )
                      }
                      target={isExternalUrl(item?.path) ? '_blank' : '_self'}
                    >
                      {item?.text}
                    </Styled.AnchorStyle>
                  </Row>
                );
              })}
            </Styled.Column>
          </Styled.FootRight>
          <div style={{ clear: 'both' }}></div>
        </Styled.FooterInnerContainer>
      </Styled.FooterContainer>
    </>
  );
};

export default Footer;
