import { graphql, useStaticQuery } from 'gatsby';
import constants from '../utils/utils';

export default () => {
  const { allContentfulColumbiaFuel } = useStaticQuery(
    graphql`
    query commonfooter {
      allContentfulColumbiaFuel(
        filter: {node_locale: {eq: "en-US"}, identifier: {type: {eq: "subFooter"}}}
      ) {
        edges {
          node {
            name
            slug
            title
            identifier {
              type
            }
            sections {
              ... on ContentfulImageWithPath {
                id
                path
                title
                type
                image {
                  file {
                    url
                  }
                }
              }
              ... on ContentfulListOfImageWithPath {
                id
                type
                listOfImagesWithPath {
                  id
                  path
                  title
                  image {
                    file {
                      url
                    }
                  }
                }
              }
              ... on ContentfulListTextWithPaths {
                id
                type
                textWithPaths {
                  text
                  type
                  path
                }
              }
            }
          }
        }
      }
    }
    
    `
  );
  return allContentfulColumbiaFuel.edges[0].node.sections;
};
