import * as React from 'react';
import { useEffect, useState } from 'react';
// import Cookies from 'universal-cookie';
import { graphql, navigate } from 'gatsby';
import DefaultHeaderMenu from '../components/defaultHeaderMenu/defaultHeaderMenu';
import Footer from '../components/footer/footer';
import SubFooter from '../components/subFooter/subFooter';
import GlobalStyles from '../styles/globalStyles';
import {
  HtmlSitemapMainDiv,
  HtmlSiteMapHeader,
  HtmlSitemapTitle,
} from '../styles';
import BootstrapNavbar from '../components/navigationBar/mainMenu/navigationBar';
import { addTrailingSlash } from '../utils';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

const HtmlSitemap = ({ data, location }) => {
  React.useLayoutEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const path = location?.pathname?.split('/')[1];
  const [showRequestCallBackProp, setShowRequestCallBackProp] = useState(false);
  const handleRequestCallBack = () => {
    setShowRequestCallBackProp(true);
  };

  let allSlugs = [
    ...data?.allContentfulColumbiaFuel?.edges
      .filter((edge) => {
        if (
          edge?.node?.slug &&
          !edge?.node?.slug?.includes('modal') &&
          !edge?.node?.slug?.includes('404')
        ) {
          return edge?.node?.slug;
        }
      })
      .map((edge) => {
        return {
          slug: addTrailingSlash(process.env.GATSBY_DOMAIN_URL + edge?.node?.slug),
          title: edge?.node?.title,
        };
      }),
    ...data?.allContentfulLocation?.edges.map((edge) => {
      return {
        slug: addTrailingSlash(process.env.GATSBY_DOMAIN_URL + '/locations/' + edge?.node?.urlSlug),
        title: edge?.node?.location,
      };
    }),
  ];

  allSlugs.push({ title: 'Search Site', slug: process.env.GATSBY_DOMAIN_URL + '/search/' });

  const flags = [],
    l = allSlugs.length;
  let SlugList = [];
  for (let i = 0; i < l; i++) {
    if (flags[allSlugs[i].slug]) {
      continue;
    }
    flags[allSlugs[i].slug] = true;
    SlugList.push(allSlugs[i]);
  }
  SlugList = SlugList.sort((a, b) => a?.slug?.localeCompare(b?.slug));
  return (
    <>
      <Helmet>
        <title>Site Map</title>
      </Helmet>
      <GlobalStyles />
      <DefaultHeaderMenu
        showRequestCallBackProp={showRequestCallBackProp}
        setShowRequestCallBackProp={setShowRequestCallBackProp}
      />
      <BootstrapNavbar
        showRequestCallBackProp={showRequestCallBackProp}
        setShowRequestCallBackProp={setShowRequestCallBackProp}
        activeLink={path}
      />
      <HtmlSitemapMainDiv>
        {SlugList.map(({ slug, title }, index) => (
          <HtmlSitemapTitle key={index}>
            <HtmlSiteMapHeader
              href={slug}
              $leftSpace={slug?.split('/')?.length - 1}
            >
              {title ?? slug}
            </HtmlSiteMapHeader>
          </HtmlSitemapTitle>
        ))}
      </HtmlSitemapMainDiv>

      <Footer showProducts={true} />
      <SubFooter />
    </>
  );
};

export default HtmlSitemap;
export const pageQuery = graphql`
  query SitemapQuery {
    allContentfulColumbiaFuel(filter: { node_locale: { eq: "en-US" },hasIndex: { ne: false }}) {
      edges {
        node {
          slug
          title
          name
        }
      }
    }
    allContentfulLocation(
      filter: {
        brand: { brand: { eq: "Columbia" }, node_locale: { eq: "en-US" } }
      }
    ) {
      edges {
        node {
          urlSlug
          location
        }
      }
    }
  }
`;
