import { graphql, useStaticQuery } from 'gatsby';
import constants from '../utils/utils';

export default () => {
  const { allContentfulColumbiaFuel } = useStaticQuery(
    graphql`
    query mainfooter {
        allContentfulColumbiaFuel(
          filter: {node_locale: {eq: "en-US"}, identifier: {type: {eq: "footer"}}}
        ) {
          edges {
            node {
              name
              slug
              title
              identifier {
                type
              }
              sections {
                ... on ContentfulFooter {
                  id
                  type
                  title
                  subText
                  socialNetworking {
                    title
                    type
                    path
                    image {
                      file {
                        url
                      }
                    }
                  }
                  products {
                    title
                    type
                    path
                    image {
                      file {
                        url
                      }
                      title
                    }
                  }
                  imageWithPath {
                    path
                    title
                    image {
                      file {
                        url
                      }
                    }
                  }
                  TextWithPaths {
                    text
                    type
                    path
                  }
                }
              }
            }
          }
        }
      }      
    `
  );
  return allContentfulColumbiaFuel.edges[0].node.sections.filter(
    (item) => item.type === constants.COMMON.FOOTER
  )[0];
};