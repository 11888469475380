import React from 'react';
import useFooter from '../../hooks/useFooter';
import {
  addTrailingSlash,
  isExternalUrlHref,
  isExternalUrlhref,
} from '../../utils';
import * as Styled from './subFooterStyle';
import isExternalUrl from '../../utils';

const SubFooter = () => {
  const footer = useFooter();
  const logo = footer.filter((section, index) => {
    return section.type === 'subFooter';
  })[0];
  const socialnet = footer.filter((section, index) => {
    return section.type === 'subFooterSocialMedia';
  })[0];
  const textwithpath = footer.filter((section, index) => {
    return section.type === 'listOfTextWithPath';
  })[0];
  return (
    <Styled.SubFooterContainer>
      <Styled.SubFooterupperDiv>
        <Styled.DivStyle>
          <Styled.AnchorStyle
            href={
              isExternalUrlHref(footer[0]?.path)
                ? footer[0]?.path
                : addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL + footer[0]?.path
                  )
            }
            target="_blank"
          >
            <Styled.LogoStyle src={logo?.image?.file?.url} />
          </Styled.AnchorStyle>
          <Styled.InternalDivStyle>
        <Styled.ListStyle>
            {socialnet?.listOfImagesWithPath?.map((item, index) => {
              return (
                <Styled.ListItem key={index}>
                  <Styled.AnchorStyle
                    title={item?.title}
                    href={
                      isExternalUrlHref(item?.path)
                        ? item?.path
                        : addTrailingSlash(
                            process.env.GATSBY_DOMAIN_URL + item?.path
                          )
                    }
                    target={isExternalUrl(item?.path) ? '_blank' : '_self'}
                  >
                    <Styled.FooterLogo
                      src={item?.image?.file?.url}
                      alt="columbia_logo"
                    />
                  </Styled.AnchorStyle>
                </Styled.ListItem>
              );
            })}
        </Styled.ListStyle>
        <Styled.ListStyle>
        {textwithpath?.textWithPaths?.map((item, index) => {
              return (
                <Styled.ListItem key={index}>
                  <Styled.AnchorStyle
                    href={
                      isExternalUrlHref(item?.path)
                        ? item?.path
                        : addTrailingSlash(
                            process.env.GATSBY_DOMAIN_URL + item?.path
                          )
                    }
                    target={isExternalUrl(item?.path) ? '_blank' : '_self'}
                  >
                    {item?.text}
                  </Styled.AnchorStyle>
                </Styled.ListItem>
              );
            })}
        </Styled.ListStyle>
        </Styled.InternalDivStyle>
      </Styled.DivStyle>
      </Styled.SubFooterupperDiv>
    </Styled.SubFooterContainer>
  );
};

export default SubFooter;
